<template>
  <v-container v-if="show">
    <v-row no-gutters>
      <v-row class="action-wrapper">
        <v-col cols="12" sm="7"></v-col>
        <v-col cols="12" sm="3">
          <v-select
            :items="items"
            label="Action"
            v-model="multiActionToPerform"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2" class="actions">
          <v-btn
            elevation="0"
            class="primary"
            style="height:40px"
            block
            @click="performAction"
          ><span v-if="!$store.state.bulkActionInProgress">Submit</span>
          <v-progress-circular
            indeterminate
            color="#FFFFFF"
            v-if="$store.state.bulkActionInProgress"
          ></v-progress-circular>
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table id="recent-campaigns">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                <v-checkbox
                  v-model="allSelectedCampaigns"
                  :value="false"
                  @change="getAllSelectedCampaigns()"
                ></v-checkbox>
              </th>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Email Template
              </th>
              <th class="text-center">
                Status
              </th>
              <th class="text-center">
                Added On
              </th>
              <th class="text-center">
                Sent On
              </th>
              <!-- <th class="text-center">
                No. of Items
              </th> -->
              <th class="text-center">
                Open Rate
              </th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in campaignsData">
              <tr
                :key="item.id"
                :class="rowColor(item.status)"
                v-if="
                  index >= (campaignPagination - 1) * ItemsPerPage &&
                    index < campaignPagination * ItemsPerPage &&
                    !gettingCampaignsInProgress &&
                    item.archived != true
                "
              >
                <td class="text-center">
                  <v-checkbox
                    v-model="selectedCampaigns"
                    :value="item.id"
                    @change="checkSelectedCampaigns"
                  ></v-checkbox>
                </td>
                <td
                  @click="goToCampaign(item.id, item.status)"
                  class="campaignName"
                >
                  <div>{{ item.name }}</div>
                </td>
                <td style="cursor: auto">
                  <v-icon
                    @click="viewEmailTemplate(item.template_name)"
                    class="viewEye"
                    v-if="item.template_name != 'Rule Based'"
                    >remove_red_eye</v-icon
                  >
                  {{ item.template_name }}
                </td>
                <td
                  class="text-center status"
                  @click="goToCampaign(item.id, item.status)"
                >
                  <span>{{ item.status }}</span>
                </td>
                <td
                  class="text-center"
                  @click="goToCampaign(item.id, item.status)"
                >
                  {{
                    getMonth(item.createdAt) +
                      "/" +
                      new Date(item.createdAt).getDate() +
                      "/" +
                      new Date(item.createdAt).getFullYear()
                  }}
                </td>
                <td
                  class="text-center"
                  @click="goToCampaign(item.id, item.status)"
                >
                  <span v-if="item.sent_time">{{
                    getMonth(item.sent_time) +
                      "/" +
                      new Date(item.sent_time).getDate() +
                      "/" +
                      new Date(item.sent_time).getFullYear()
                  }}</span
                  ><span v-if="!item.sent_time">-</span>
                </td>
                <!-- <td
                  class="text-center"
                  @click="goToCampaign(item.id, item.status)"
                >
                  {{ item.clients.length }}
                </td> -->
                <td
                  v-if="item.status == 'SENT'"
                  class="text-center"
                  @click="goToCampaign(item.id, item.status)"
                >
                  {{ openRate(item.clients) }}
                </td>
                <td
                  v-if="item.status != 'SENT'"
                  class="text-center"
                  @click="goToCampaign(item.id, item.status)"
                >
                  -
                </td>
                <!-- <td><v-btn class="more-options" :to="{name: 'Campaign Detail', params:{name:item.user.replace(/\W+/g, '-').toLowerCase(), id: item.id} }">&#x2026;</v-btn></td> -->
                <td class="more-options text-center">
                  <a
                    @click="archiveCampaign(item)"
                    :class="'disabled-' + campaignActionBtnDisabled"
                    title="Archive"
                    v-if="item.status != 'SENDING'"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#777BD2"
                      v-if="archiveDialogId == item.id"
                    ></v-progress-circular>
                    <v-icon v-if="archiveDialogId != item.id && item.status == 'SENT'" class="archiveBtn"
                      >archive</v-icon
                    >
                  </a>
                  <router-link
                    :to="{
                      name: 'Campaign Detail',
                      params: { id: item.id, edit: true }
                    }"
                    v-if="item.status != 'SENT' && item.status != 'SENDING'"
                    :class="'disabled-' + campaignActionBtnDisabled"
                    ><v-icon>edit</v-icon></router-link
                  >
                  <router-link
                    :to="{
                      name: 'Campaign Detail',
                      params: { id: item.id, edit: false }
                    }"
                    v-if="item.status == 'SENT' || item.status == 'SENDING'"
                    :class="'disabled-' + campaignActionBtnDisabled"
                    ><v-icon>remove_red_eye</v-icon></router-link
                  >
                  <a
                    @click="deleteCampaign(item)"
                    :class="'disabled-' + campaignActionBtnDisabled"
                    v-if="item.status != 'SENDING'"
                  >
                    <v-progress-circular
                      indeterminate
                      color="#777BD2"
                      v-if="selectedID == item.id"
                    ></v-progress-circular>
                    <v-icon v-if="selectedID != item.id" class="deleteBtn"
                      >delete</v-icon
                    >
                  </a>
                  <transition name="fade-transition"
                    ><confirmation-dialog
                      v-if="dialogId == item.id"
                      actionName="deleteCampaign"
                      primaryTxt="Delete"
                      :item="item"
                      @close="cancel()"
                    ></confirmation-dialog
                  ></transition>

                  <transition name="fade-transition"
                    ><confirmation-dialog
                      v-if="archiveDialogId == item.id"
                      actionName="archiveCampaign"
                      primaryTxt="Archive"
                      positiveButtonText="archive"
                      messageText="Are you sure you want to archive?"
                      :item="item"
                      @close="cancel()"
                    ></confirmation-dialog
                  ></transition>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <template v-if="campaignsData.length && !gettingCampaignsInProgress">
        <div class="text-center" style="width: 100%;margin: 25px 0px;">
          <v-pagination
            v-model="campaignPagination"
            :length="getPaginationLength()"
            @input="checkSelectedCampaigns"
            class="elevation-0 pagination-row"
          ></v-pagination>
        </div>
      </template>
      <template v-if="!campaignsData.length && !gettingCampaignsInProgress">
        <p class="empty-campaign-msg">
          No campaign has been added. Get started by adding a new campaign.
        </p>
        <v-btn
          dark
          class="create-campaign primary elevation-0"
          @click="openCsvUploadBox"
          >Create Campaign</v-btn
        >
      </template>
      <template>
        <v-progress-circular
          indeterminate
          color="#777BD2"
          v-if="gettingCampaignsInProgress"
          class="progressLoader"
        ></v-progress-circular>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
export default {
  name: "AllCampaigns",
  components: {
    ConfirmationDialog
  },
  data() {
    return {
      userEmail: "",
      show: false,
      campaignPagination: 1,
      ItemsPerPage: 10,
      selectedCampaigns: [],
      allSelectedCampaigns: false,
      items: ['Archive', 'Delete'],
      multiActionToPerform: null,
      currentPageCampaigns: []
    };
  },
  mounted() {
    if (this.$store.state.user == null || this.$store.state.user == undefined) {
      this.$router.push({ path: "/sign-in" });
    } else {
      this.userEmail = this.$store.state.user.attributes.email;
      this.showCampaigns();
      this.show = true;
    }
  },
  methods: {
    openRate(clients){

     if (clients) {
        let totalSentEmails;
        if(clients.filter(e => e.status == 'subscribed').length){
          totalSentEmails = clients.filter(e => e.status == 'subscribed').length;
        }
        else{
          totalSentEmails = clients.length
        }
        let openedEmails = clients.filter(
          e => e.delivery_status == 1
        ).length;
        let result = (openedEmails / totalSentEmails) * 100;
        return parseInt(result)+"%";
      }

      return 0;
    },
    getAllSelectedCampaigns(){
      if(!this.$store.state.bulkActionInProgress){
        var _this = this;
        this.selectedCampaigns = [];
        if(this.allSelectedCampaigns == true){
          this.campaignsData.forEach(function(element, index){
            if(index >= (_this.campaignPagination - 1) * _this.ItemsPerPage && index < _this.campaignPagination * _this.ItemsPerPage && element.archived != true){
              _this.selectedCampaigns.push(element.id);
            }  
          });
        }
        else{
          this.selectedCampaigns = [];
        }
      }
    },
    performAction(){
      var data = [];
      var item = null;
      if(this.selectedCampaigns.length > 0){
        if(this.multiActionToPerform == 'Archive'){
          this.$store.state.bulkActionInProgress = true;
          this.selectedCampaigns.forEach(element => {
            item = {
              "id": element,
              "action": "a"
            }
            data.push(item);
          });
          this.$store.dispatch('bulkAction', data);
          this.allSelectedCampaigns = false
        }
        else if(this.multiActionToPerform == 'Delete'){
          this.$store.state.bulkActionInProgress = true;
          this.selectedCampaigns.forEach(element => {
            item = {
              "id": element,
              "action": "d"
            }
            data.push(item);
          });
          this.$store.dispatch('bulkAction', data);
          this.allSelectedCampaigns = false;
        }
        else{
          this.$store.state.bulkActionInProgress = false;
          this.$store.state.responseMsg = 'Kindly select an action to perform.';
          this.$store.state.ResponseClass = 'error';
        }
      }
      else{
        this.$store.state.responseMsg = 'Kindly select atleast one row.';
        this.$store.state.ResponseClass = 'error';
      }
    },
    checkSelectedCampaigns(){
      console.log('yes')
      var _this = this;
      this.currentPageCampaigns = [];
      this.campaignsData.forEach(function(element, index){
        if(index >= (_this.campaignPagination - 1) * _this.ItemsPerPage && index < _this.campaignPagination * _this.ItemsPerPage && element.archived != true){
          _this.currentPageCampaigns.push(element.id);
        }  
      });
      function arrayCompare(_arr1, _arr2) {
          if (
            !Array.isArray(_arr1)
            || !Array.isArray(_arr2)
            || _arr1.length !== _arr2.length
            ) {
              return false;
            }
          
          // .concat() to not mutate arguments
          const arr1 = _arr1.concat().sort();
          const arr2 = _arr2.concat().sort();
          
          for (let i = 0; i < arr1.length; i++) {
              if (arr1[i] !== arr2[i]) {
                  return false;
              }
          }
          
          return true;
      }
      if(arrayCompare(_this.currentPageCampaigns, _this.selectedCampaigns) == true){
        this.allSelectedCampaigns = true;
      }
      else{
        console.log(_this.currentPageCampaigns)
        console.log(_this.selectedCampaigns)
        this.allSelectedCampaigns = false;
      }
    },
    getPaginationLength() {
      var dataLength = this.$store.state.campaigns.data.length;
      var itemsPerPage = this.ItemsPerPage; // is number of items per page
      var x = dataLength / itemsPerPage;
      var totalPageNumber;
      if (x >= 1) {
        if (dataLength % itemsPerPage > 0) {
          totalPageNumber = Math.floor(dataLength / itemsPerPage) + 1;
        } else {
          totalPageNumber = Math.floor(dataLength / itemsPerPage);
        }
      } else {
        totalPageNumber = 1;
      }
      return totalPageNumber;
    },
    showCampaigns() {
      this.$store.state.gettingCampaignsInProgress = true;
      this.$store.dispatch("getCampaigns", {
        user: this.userEmail,
        status: ""
      });
    },
    getMonth(time) {
      var month = new Date(time).getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      return month;
    },
    showbox(item) {
      this.selectedId = item;
    },
    deleteCampaign(item) {
      this.$store.state.campaignActionBtnDisabled = true;
      this.$store.state.dialogId = item.id;
    },
    archiveCampaign(item) {
      this.$store.state.campaignActionBtnDisabled = true;
      this.$store.state.archiveDialogId = item.id;
    },
    goToCampaign(campaignId, campaignStatus) {
      if (campaignStatus == "SENT") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: false,
            schedule: false,
            sending: false
          }
        });
      } else if (campaignStatus == "PENDING") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: true,
            schedule: false,
            sending: false
          }
        });
      } else if (campaignStatus == "SCHEDULED") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: false,
            schedule: true,
            sending: false
          }
        });
      } else if (campaignStatus == "SENDING") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: false,
            schedule: false,
            sending: true
          }
        });
      } else if (campaignStatus == "UNSCHEDULED") {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: false,
            pending: true,
            schedule: false,
            sending: false
          }
        });
      } else {
        this.$router.push({
          name: "Campaign Detail",
          params: {
            id: campaignId,
            edit: true,
            pending: false,
            schedule: false,
            sending: false
          }
        });
      }
    },
    openCsvUploadBox() {
      this.$router.push({ path: "/" });
    },
    viewEmailTemplate(emailTemplate) {
      if (this.emailTemplatesData.find(e => e["name"] == emailTemplate)) {
        this.$store.state.TemplateData = this.emailTemplatesData.find(
          e => e["name"] == emailTemplate
        );
      } else {
        this.$store.state.responseMsg = "Email Template Not Found";
        this.$store.state.ResponseClass = "error";
      }
    },
    getTemplates() {
      this.$store.dispatch("getEmailTemplates", this.userEmail);
    },
    cancel() {
      this.$store.state.dialogId = "";
      this.$store.state.archiveDialogId = "";
      this.$store.state.campaignActionBtnDisabled = false;
    },
    rowColor(status) {
      var rowClass = "";
      if (status == "SCHEDULED") {
        rowClass = "scheduled";
      } else if (status == "PENDING") {
        rowClass = "pending";
      } else if (status == "SENT") {
        rowClass = "sent";
      } else if (status == "SENDING") {
        rowClass = "sending";
      } else if (status == "UNSCHEDULED") {
        rowClass = "unscheduled";
      } else {
        rowClass = "";
      }
      return rowClass;
    }
  },
  computed: {
    campaignsData() {
      var x = "";
      if (
        this.$store.state.campaigns == undefined ||
        this.$store.state.campaigns == null
      ) {
        this.showCampaigns();
      }
      if (this.$store.state.campaigns) {
        x = this.$store.state.campaigns.data;
      }
      return x;
    },
    selectedID() {
      return this.$store.state.selectedID;
    },
    campaignActionBtnDisabled() {
      return this.$store.state.campaignActionBtnDisabled;
    },
    gettingCampaignsInProgress() {
      return this.$store.state.gettingCampaignsInProgress;
    },
    dialogId: {
      get() {
        return this.$store.state.dialogId;
      },
      set(value) {
        this.$store.state.dialogId = value;
      }
    },
    archiveDialogId: {
      get() {
        return this.$store.state.archiveDialogId;
      },
      set(value) {
        this.$store.state.archiveDialogId = value;
      }
    },
    archiveInProgress() {
      return this.$store.state.archiveInProgress;
    },
    emailTemplatesData() {
      var x = "";
      if (
        (this.$store.state.EmailTemplates == undefined ||
          this.$store.state.EmailTemplates == null) &&
        this.userEmail != ""
      ) {
        this.getTemplates();
      } else if (this.$store.state.EmailTemplates && this.userEmail != "") {
        x = this.$store.state.EmailTemplates.data;
      }
      return x;
    }
  },
  watch: {
    /* campaignsData (newCount) {
      // Our fancy notification (2).
      console.log(`We have ${newCount} fruits now, yay!`)
    } */
  },
  created() {}
};
</script>
<style scoped>
#recent-campaigns {
  width: 100%;
}
tr td {
  cursor: pointer;
}
tr td:last-child {
  cursor: auto;
}
#recent-campaigns .more-options {
  font-size: 25px !important;
  /* background: none;
  box-shadow: none;
  padding: 0;
  height: fit-content;
  min-width: max-content; */
}
#recent-campaigns thead {
  background-color: #cdeaff;
}
#recent-campaigns thead th {
  font-size: 18px;
  font-weight: 400;
}
#recent-campaigns tbody td {
  font-size: 16px;
  font-weight: 400;
}
.progressLoader.v-progress-circular {
  height: 100px !important;
  width: 100px !important;
  margin: 15px auto;
}
.more-options button {
  margin-right: 10px;
}
.more-options button,
.more-options a,
.viewEye {
  opacity: 0.6;
}
.more-options button:hover,
.more-options a:hover,
.viewEye:hover {
  opacity: 1;
}
.empty-campaign-msg {
  width: 100%;
  text-align: center;
  font-size: 20px;
  padding: 25px 0px 15px;
}
.create-campaign {
  margin: auto;
  background-color: #1f95ee !important;
  padding: 25px 30px;
}
.more-options button:first-child,
.more-options a:first-child {
  margin-right: 5px;
}
.campaignName > div {
  max-width: 350px;
  overflow: hidden;
  overflow-wrap: break-word;
}
a.disabled-false {
  pointer-events: auto;
}
a.disabled-true {
  pointer-events: none;
}
tr.scheduled {
  background-color: #f5e8ff;
  /* border-left: 5px solid #BE89F2; */
}
tr.pending {
  background-color: #ffece7;
  /* border-left: 5px solid #FB8474; */
}
tr.sent {
  background-color: #eaffe7;
  /* border-left: 5px solid #8CC383; */
}
tr.sending {
  background-color: #f7c49f;
  /* border-left: 5px solid #8CC383; */
}
tr.unscheduled {
  background-color: #d5d5d5;
}
tr.unscheduled .status span {
  background-color: #878787;
}
tr.scheduled .status span {
  background-color: #be89f2;
}
tr.pending .status span {
  background-color: #fb8474;
}
tr.sent .status span {
  background-color: #8cc383;
}
tr.sending .status span {
  background-color: #f08b45;
}
thead {
  background-color: #d9daff;
}
thead > tr > th {
  color: #575757 !important;
}
.status span {
  min-width: 95px;
  display: block;
  border-radius: 20px;
  color: #fff;
  padding: 5px 15px;
}
.pagination-row >>> .v-pagination .v-pagination__item,
.pagination-row >>> .v-pagination .v-pagination__navigation {
  outline: none !important;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
}
.pagination-row >>> .v-pagination .v-pagination__item:hover,
.pagination-row >>> .v-pagination .v-pagination__navigation:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
td.campaignName{
  max-width: 200px;
}
.v-data-table >>> .v-data-table__wrapper table{
  min-width: 1050px;
}

@media screen and (max-width: 850px) {
  #recent-campaigns .more-options {
    padding: 2px;
  }
}
</style>
